import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import { Switch } from '@headlessui/react'
import Loading from "../../common/Loading";
import * as api from "../../../api/api";
import {resultBoolean, convertBoolean} from "../../common/Global";
import {CModal} from "@coreui/react";
import UserPointModal from "./UserPointModal";
import UserMoneyModal from "./UserMoneyModal";
import UserMessageModal from "./UserMessageModal";
import numeral from "numeral";

const EmptyCell = ({ span = 1 }) => (
    <div className={`col-span-${span}`}/>
);

// 헤더-값 쌍을 표시하는 공통 컴포넌트 - col-span-2로 수정
const InfoItem = ({ label, children, bgColor = "bg-gray-50" }) => {
  return (
    <>
      <div className={`${bgColor} p-2 flex items-center col-span-1`}>
        <span className={"text-sm font-bold"}>{label}</span>
      </div>
      <div className="p-2 flex items-center col-span-1">
        {children}
      </div>
    </>
  );
};

// 텍스트 값 표시용 컴포넌트
const TextValue = ({ value, isBold = false }) => (
  <span className={`text-sm ${isBold ? "font-bold" : ""}`}>{value}</span>
);

// 숫자 포맷팅 컴포넌트
const NumberValue = ({ value, isBold = false, color = "" }) => (
  <span className={`text-sm ${isBold ? "font-bold" : ""} ${color}`}>{numeral(value).format("0,0")} 원</span>
);

// 입력 필드 컴포넌트
const InputField = ({ name, value, onChange, type = "text" }) => (
  <input 
    name={name} 
    className={"w-full rounded border px-2 py-1 text-sm"}
    type={type} 
    value={value} 
    onChange={onChange}
  />
);

// 스위치 토글 컴포넌트
const ToggleSwitch = ({ value, onChange, useResultBoolean = false }) => (
  <Switch
    onChange={value => onChange(value)}
    checked={useResultBoolean ? resultBoolean(value) : convertBoolean(value)}
    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
  >
    <span
      aria-hidden="true"
      className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
    />
  </Switch>
);

// 공통으로 사용되는 RollingRateInput 컴포넌트
const RollingRateInput = ({ item, onChange }) => {
    return (
        <div className={"border rounded p-2"}>
            <div className={"bg-gray-100 p-2 mb-2 font-bold text-sm"}>{item.gameNm}</div>
            <div className={"flex flex-col gap-2"}>
                <div className={"flex flex-col gap-1"}>
                    <div className={"flex flex-row items-center gap-1"}>
                        <input 
                            className={"w-[50px] border px-1 text-sm"}
                            type="text"
                            value={item.rollingRate}
                            onChange={(e) => onChange(e.target.value, item)}
                        />
                        <span className={"text-sm text-blue-500"}>% (상위수수료율 : {item.recommenderRollingRate})</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

// 게임 섹션 컴포넌트
const GameSection = ({ title, gameDiv, defaultRate, setDefaultRate, userInfo, setUserInfo }) => {
    const handleRateChange = (inputValue, item) => {
        const updatedList = userInfo.userRollingList.map(listItem => 
            (listItem.gameDiv === item.gameDiv && listItem.gameType === item.gameType) 
                ? { ...listItem, rollingRate: inputValue } 
                : listItem
        );
        
        if (inputValue === '' || isNaN(inputValue)) {
            setUserInfo({
                ...userInfo,
                userRollingList: updatedList
            });
            return;
        }

        const numValue = parseFloat(inputValue);
        
        if (numValue > 100) {
            window.alert('100%를 초과할 수 없습니다.');
            return;
        }
        
        if (item.isTopUser === 'N') {
            if (numValue <= item.recommenderRollingRate) {
                setUserInfo({
                    ...userInfo,
                    userRollingList: updatedList
                });
            } else {
                window.alert(`상위수수료율(${item.recommenderRollingRate}%)을 초과할 수 없습니다.`);
            }
        } else {
            setUserInfo({
                ...userInfo,
                userRollingList: updatedList
            });
        }
    };

    const applyDefaultRate = () => {
        if (defaultRate === '' || isNaN(defaultRate)) return;
        
        const numValue = parseFloat(defaultRate);
        if (numValue > 100) {
            window.alert('100%를 초과할 수 없습니다.');
            return;
        }

        const updatedList = [...userInfo.userRollingList];
        userInfo.userRollingList.forEach((item, idx) => {
            if (item.gameDiv === gameDiv) {
                if (item.isTopUser === 'N') {
                    if (numValue <= item.recommenderRollingRate) {
                        updatedList[idx] = { ...item, rollingRate: defaultRate };
                    } else {
                        window.alert(`${item.gameNm}의 상위수수료율(${item.recommenderRollingRate}%)을 초과할 수 없습니다.`);
                    }
                } else {
                    updatedList[idx] = { ...item, rollingRate: defaultRate };
                }
            }
        });
        setUserInfo({ ...userInfo, userRollingList: updatedList });
    };

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 bg-gray-100 p-2">
                <span className="text-sm font-bold">{title} 전체 요율 적용</span>
                <input 
                    className="w-[50px] border px-1 text-sm"
                    type="text"
                    placeholder="요율"
                    value={defaultRate}
                    onChange={(e) => setDefaultRate(e.target.value)}
                />
                <span className="text-sm">%</span>
                <button 
                    className="px-2 py-1 bg-red-500 text-white text-xs rounded"
                    onClick={applyDefaultRate}
                >
                    적용
                </button>
            </div>
            <div className="grid grid-cols-4 gap-4">
                {userInfo.userRollingList
                    .filter(item => item.gameDiv === gameDiv)
                    .map((item, idx) => (
                        <RollingRateInput 
                            key={`rolling_${idx}`}
                            item={item}
                            onChange={handleRateChange}
                        />
                    ))}
            </div>
        </div>
    );
};

const UserDetail = (props) => {
    const {user} = useSelector((props) => props.account)
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({
        companyCode: "", companyType: "", parentId: "", userType: "6000",
        userId: "", userLevel: 1, userName: "", password: "", nickName: "", mobileNo: "", recommendCode: "",
        bankAccount: "", bankName: "", bankOwner: "",
        exchangePass: "", exchangeTime: 0,
        filler1: "",
        recommendYn: 1, bettingYn: 1, moneyRetYn: 1, pointRetYn: 1, transferPointYn: 1, blackYn: 0,
        status: 1, useYn: 1,
        upperUserList: [],
        userSportsSetting: {},
        userSportsPer: {},
        userRollingList: [],
        userCashSummary: {}
    })

    const [sportsSetting, setSportsSetting] = useState({
        sportsYn: 1,
        casinoYn: 1,
        slotYn: 1,
        minigameYn: 1,
    })

    const [pointModal, setPointModal] = useState({visible: false, type: "0"})
    const [moneyModal, setMoneyModal] = useState({visible: false, type: "0"})
    const [messageModal, setMessageModal] = useState({visible: false})

    const [searchParam] = useSearchParams()
    const userId = searchParam.get("userId") ? searchParam.get("userId") : ""
    const procUserId = searchParam.get("procUserId") ? searchParam.get("procUserId") : ""

    const [casinoDefaultRate, setCasinoDefaultRate] = useState("")
    const [slotDefaultRate, setSlotDefaultRate] = useState("")

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        setLoading(true)
        api.getGUser(userId).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setUserInfo(data)

                if (data.userSportsSetting !== null) {
                    setSportsSetting(data.userSportsSetting)
                }
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getSportsBaseList = () => {
        api.getSportsBaseList().then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setSportsSetting(data)
            }
        })
    }

    const handleUserInfo = (e) => {
        const {name, value} = e.target;
        setUserInfo({
            ...userInfo,
            [name]: value
        })
    }

    const handleUserGameSetting = (e) => {
        const {name, value} = e.target
    }

    const save = () => {
        if (window.confirm("저장하시겠어요?")) {
            // setLoading(true)
            setLoading(true)
            api.putGUser(userInfo).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const saveSportsSetting = () => {
        if (window.confirm("저장하시겠어요?")) {
            const params = {
                seq: sportsSetting.seq ? sportsSetting.seq : -1,
                userId: userId,
                ...sportsSetting
            }

            setLoading(true)
            api.putSportsSetting(params).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const saveSportsPer = () => {
        if (window.confirm("저장하시겠어요?")) {
            const params = {
                companyCode: userInfo.companyCode,
                userRollingList: userInfo.userRollingList
            }
            console.log("### params :: ", JSON.stringify(params))

            setLoading(true)
            api.putUserRolling(userInfo.userRollingList).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onButtonMenu = (value) => {
        switch (value) {
            case 0:
                setPointModal({visible: true, type: "0"})
                break;
            case 1:
                setPointModal({visible: true, type: "1"})
                break;
            case 2:
                setMoneyModal({visible: true, type: "0"})
                break;
            case 3:
                setMoneyModal({visible: true, type: "1"})
                break;
            case 4:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/betting';
                }
                break;
            case 5:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/deposit';
                }
                break;
            case 6:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/withdraw';
                }
                break;
            case 7:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/point';
                }
                break;
            case 8:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/loginLog';
                }
                break;
            case 9:
                setMessageModal({visible: true});
                break;
            case 10:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = `/message/list?userId=${userId}`;
                }
                break;
            case 11:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/partner/change';
                }
                break;
            case 12:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/settle/user-list';
                }
                break;
            case 13:
                if (window.opener) {
                    window.opener.localStorage.setItem('targetUserId', userId);
                    window.opener.location.href = '/user/history';
                }
                break;
        }
    }

    const procMoney = (money) => {
        const params = {
            companyCode: userInfo.companyCode,
            ...money,
            procUserId: procUserId
        }

        setLoading(true)
        api.postMoney(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.")
                return
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const procPoint = (point) => {
        const params = {
            companyCode: userInfo.companyCode,
            ...point,
            procUser: procUserId
        }

        setLoading(true)
        api.postPoint(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.")
                return
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const procMessage = (message) => {
        const params = {
            companyCode: userInfo.companyCode,
            userId: userInfo.userId,
            subject: message.subject,
            contents: message.contents
        }

        setLoading(true)
        const headers = {
            "admin-id": procUserId
        }
        api.sendNote(params, headers).then(result => {
            const {status} = result
            if (status === 200) {
                window.alert("쪽지가 성공적으로 전송되었습니다.")
                return
            }
        })
        .catch(ex => {
            window.alert(ex.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className={"flex flex-col p-2 gap-1"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>회원 관리 &gt; 회원 상세</p>
            <div className={"flex flex-row gap-1"}>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(0)}>포인트 지급</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(1)}>포인트 회수</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(2)}>보유금 지급</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(3)}>보유금 회수</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(4)}>배팅 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(5)}>입금 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(6)}>출금 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(7)}>포인트 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(8)}>접속 로그</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(9)}>쪽지 전송</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(10)}>쪽지 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(11)}>파트너 변경</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(12)}>일별 통계</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(13)}>정보 수정 이력</button>
            </div>
            <div className={"border mt-1"}>
                <div className={"flex flex-row p-2 gap-2"}>
                    <span className={"text-sm font-bold"}>회원 아이디</span>
                    <input className={"text-sm border px-2"} value={userId} disabled={true}/>
                    <button className={"bg-gray-700 px-3 rounded text-white text-sm"} onClick={search}>조회</button>
                </div>
            </div>
            <div className={"flex flex-col gap-2"}>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>유저정보</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={save}>저장</button>
                    </div>
                    <div className="grid grid-cols-6 gap-x-4 gap-y-2 p-3 border">
                        {/* 첫 번째 행 */}
                        <InfoItem label="아이디">
                            <TextValue value={userInfo.userId} isBold={true} />
                        </InfoItem>
                        <InfoItem label="이름">
                            <InputField name="userName" value={userInfo.userName} onChange={handleUserInfo} />
                        </InfoItem>
                        <InfoItem label="닉네임">
                            <InputField name="nickName" value={userInfo.nickName} onChange={handleUserInfo} />
                        </InfoItem>

                        {/* 두 번째 행 */}
                        <InfoItem label="휴대폰번호">
                            <InputField name="mobileNo" value={userInfo.mobileNo} onChange={handleUserInfo} />
                        </InfoItem>
                        <InfoItem label="회원레벨">
                            <select name={"userLevel"} className={"w-full rounded border px-2 text-sm"} value={userInfo.userLevel} onChange={handleUserInfo}>
                                <option value={"1"}>1</option>
                                <option value={"2"}>2</option>
                                <option value={"3"}>3</option>
                                <option value={"4"}>4</option>
                                <option value={"5"}>5</option>
                                <option value={"6"}>6</option>
                            </select>
                        </InfoItem>
                        <InfoItem label="비밀번호">
                            <InputField name="password" value={userInfo.password} onChange={handleUserInfo} type="password" />
                        </InfoItem>

                        {/* 세 번째 행 */}
                        <InfoItem label="은행">
                            <InputField name={"bankName"} value={userInfo.bankName} onChange={handleUserInfo} />
                        </InfoItem>
                        <InfoItem label="계좌번호">
                            <InputField name={"bankAccount"} value={userInfo.bankAccount} onChange={handleUserInfo} />
                        </InfoItem>
                        <EmptyCell span={2} />

                        <InfoItem label="예금주">
                            <InputField name="bankOwner" value={userInfo.bankOwner} onChange={handleUserInfo} />
                        </InfoItem>
                        <InfoItem label="환전 비밀번호">
                            <InputField name="exchangePass" value={userInfo.exchangePass} onChange={handleUserInfo} type="password" />
                        </InfoItem>
                        <EmptyCell span={2} />

                        {/* 네 번째 행 */}
                        <InfoItem label="가입일">
                            <TextValue value={userInfo.instTime} />
                        </InfoItem>
                        <InfoItem label="가입코드">
                            <TextValue name="userId" value={userInfo.userId} onChange={handleUserInfo} />
                        </InfoItem>
                        <EmptyCell span={2} />

                        {/* --------------------------------------------- */}
                        <InfoItem label="보유머니">
                            <NumberValue value={userInfo.userCash} />
                        </InfoItem>
                        <InfoItem label="보유포인트">
                            <NumberValue value={userInfo.userPoint} />
                        </InfoItem>
                        <EmptyCell span={2} />

                        {/* --------------------------------------------- */}
                        <InfoItem label="총 베팅액">
                            <NumberValue value={userInfo.userCashSummary.totalBettingAmount} />
                        </InfoItem>
                        <InfoItem label="총 당첨액">
                            <NumberValue value={userInfo.userCashSummary.totalWinningAmount} color={"text-green-500"} />
                        </InfoItem>
                        <InfoItem label="베팅/당첨 수익">
                            <NumberValue value={userInfo.userCashSummary.totalBettingProfit}
                                         color={userInfo.userCashSummary.totalBettingProfit >= 0 ? "text-green-500" : "text-red-500"}/>
                        </InfoItem>

                        {/* --------------------------------------------- */}
                        <InfoItem label="입금액">
                            <NumberValue value={userInfo.userCashSummary.totalDeposit} color={"text-green-500"} />
                        </InfoItem>
                        <InfoItem label="출금액">
                            <NumberValue value={userInfo.userCashSummary.totalWithdraw} color={"text-red-500"} />
                        </InfoItem>
                        <InfoItem label="입금/출금 수익">
                            <NumberValue value={userInfo.userCashSummary.totalCashProfit}
                                         color={userInfo.userCashSummary.totalCashProfit >= 0 ? "text-green-500" : "text-red-500"}/>
                        </InfoItem>

                        {/* --------------------------------------------- */}
                        <InfoItem label="당일 입금액">
                            <NumberValue value={userInfo.userCashSummary.todayDeposit} color={"text-green-500"} />
                        </InfoItem>
                        <InfoItem label="당일 출금액">
                            <NumberValue value={userInfo.userCashSummary.todayWithdraw} color={"text-red-500"} />
                        </InfoItem>
                        <EmptyCell span={2} />

                        {/* --------------------------------------------- */}
                        <InfoItem label="마지막 로그인">
                            <TextValue value={userInfo.lastLoginHistory ? userInfo.lastLoginHistory.instTime : ""} />
                        </InfoItem>
                        <InfoItem label="마지막 로그인 IP">
                            <TextValue value={userInfo.lastLoginHistory ? userInfo.lastLoginHistory.ip : ""} />
                        </InfoItem>
                        <EmptyCell span={2} />

                        {/* --------------------------------------------- */}
                        <InfoItem label="추천인 허용">
                            <ToggleSwitch 
                                value={userInfo.recommendYn} 
                                onChange={(value) => {
                                    setUserInfo({
                                        ...userInfo,
                                        recommendYn: convertBoolean(value)
                                    })
                                }} 
                            />
                        </InfoItem>
                        <InfoItem label="추천인 보유 머니 지급 회수 권한">
                            <ToggleSwitch 
                                value={userInfo.moneyRetYn} 
                                onChange={(value) => {
                                    setUserInfo({
                                        ...userInfo,
                                        moneyRetYn: convertBoolean(value)
                                    })
                                }} 
                            />
                        </InfoItem>
                        <InfoItem label="추천인 보유 포인트 지급 회수 권한">
                            <ToggleSwitch
                                value={userInfo.pointRetYn}
                                onChange={(value) => {
                                    setUserInfo({
                                        ...userInfo,
                                        pointRetYn: convertBoolean(value)
                                    })
                                }}
                                useResultBoolean={true}
                            />
                        </InfoItem>

                        {/* --------------------------------------------- */}
                        <InfoItem label="정지 설정">
                            <ToggleSwitch 
                                value={userInfo.blackYn} 
                                onChange={(value) => {
                                    setUserInfo({
                                        ...userInfo,
                                        blackYn: convertBoolean(value)
                                    })
                                }}
                                useResultBoolean={true}
                            />
                        </InfoItem>

                        {/* 아홉 번째 행 */}
                        <InfoItem label="로그인 가능 여부">
                            <ToggleSwitch 
                                value={userInfo.status} 
                                onChange={(value) => {
                                    setUserInfo({
                                        ...userInfo,
                                        status: convertBoolean(value)
                                    })
                                }}
                                useResultBoolean={true}
                            />
                        </InfoItem>
                        <InfoItem label="배팅 가능">
                            <ToggleSwitch 
                                value={userInfo.bettingYn} 
                                onChange={(value) => {
                                    setUserInfo({
                                        ...userInfo,
                                        bettingYn: convertBoolean(value)
                                    })
                                }}
                                useResultBoolean={true}
                            />
                        </InfoItem>
                        <InfoItem label="포인트 전환">
                            <ToggleSwitch
                                value={userInfo.transferPointYn}
                                onChange={(value) => {
                                    setUserInfo({
                                        ...userInfo,
                                        transferPointYn: convertBoolean(value)
                                    })
                                }}
                                useResultBoolean={true}
                            />
                        </InfoItem>
                        <EmptyCell span={4} />

                        {/* 열 번째 행 - 상위 파트너 리스트 */}
                        <div className="col-span-2 bg-gray-50 p-2 flex items-center">
                            <span className="text-sm font-bold">상위 파트너 리스트</span>
                        </div>
                        <div className="col-span-4 p-2 flex items-center">
                            {userInfo.upperUserList.length > 0 &&
                                <div className={"flex flex-row gap-1 items-center"}>
                                    {userInfo.upperUserList.map((item, idx) => (
                                        <span key={String(idx)} className={"text-xs"}>
                                            <span className={"text-sm font-bold"}>{item}</span>{" >>"}
                                        </span>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>메모</span>
                    </div>
                    <div className={"flex h-[100px]"}>
                        <textarea name={"filler1"} className={"flex-1 p-1 border-1 text-sm"} value={userInfo.filler1} onChange={handleUserInfo} />
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row items-center gap-2"}>
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(카지노)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(슬롯)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(미니게임)</span>*/}
                        <span className={"p-1 rounded-t border-t-2 border-x-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(스포츠)</span>
                        {/*<span className={"p-1 rounded border-2 text-sm font-bold"}>받치기 요율 설정</span>*/}
                    </div>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>게임목록</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={saveSportsSetting}>저장</button>
                    </div>
                    <div className={"grid grid-cols-4 gap-2 p-2 border-1"}>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>스포츠</span>
                            <ToggleSwitch
                                value={sportsSetting.sportsYn}
                                onChange={(value) => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        sportsYn: convertBoolean(value)
                                    })
                                }}
                            />
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>카지노</span>
                            <ToggleSwitch
                                value={sportsSetting.casinoYn}
                                onChange={(value) => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        casinoYn: convertBoolean(value)
                                    })
                                }}
                            />
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>슬롯</span>
                            <ToggleSwitch
                                value={sportsSetting.slotYn}
                                onChange={(value) => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        slotYn: convertBoolean(value)
                                    })
                                }}
                            />
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>미니게임</span>
                            <ToggleSwitch
                                value={sportsSetting.minigameYn}
                                onChange={(value) => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        minigameYn: convertBoolean(value)
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row items-center gap-2"}>
                        <span className={"p-1 rounded-t border-t-2 border-x-2 border-indigo-500 text-sm font-bold"}>게임별 요율설정</span>
                    </div>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>게임목록</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={saveSportsPer}>저장</button>
                    </div>
                    <div className="flex flex-col gap-4">
                        {/* 스포츠/미니게임 영역 */}
                        <div className="grid grid-cols-4 gap-4">
                            {userInfo.userRollingList
                                .filter(item => item.gameDiv === 'sport' || item.gameDiv === 'minigame')
                                .map((item, index) => {
                                    return <div key={`rolling_${index}`} className={"border rounded p-2"}>
                                        <div className={"bg-gray-100 p-2 mb-2 font-bold text-sm"}>{item.gameNm}</div>
                                        <div className={"flex flex-col gap-2"}>
                                            <div className={"flex flex-col gap-1"}>
                                                <div className={"flex flex-row items-center gap-1"}>
                                                    <input 
                                                        className={"w-[50px] border px-1 text-sm"}
                                                        type="text"
                                                        value={item.rollingRate}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const updatedList = userInfo.userRollingList.map(listItem => 
                                                                (listItem.gameDiv === item.gameDiv && listItem.gameType === item.gameType) 
                                                                    ? { ...listItem, rollingRate: inputValue } 
                                                                    : listItem
                                                            );
                                                            
                                                            if (inputValue === '' || isNaN(inputValue)) {
                                                                setUserInfo({
                                                                    ...userInfo,
                                                                    userRollingList: updatedList
                                                                });
                                                                return;
                                                            }

                                                            const numValue = parseFloat(inputValue);
                                                            
                                                            if (numValue > 100) {
                                                                window.alert('100%를 초과할 수 없습니다.');
                                                                return;
                                                            }
                                                            
                                                            if (item.isTopUser === 'N') {
                                                                if (numValue <= item.recommenderRollingRate) {
                                                                    setUserInfo({
                                                                        ...userInfo,
                                                                        userRollingList: updatedList
                                                                    });
                                                                } else {
                                                                    window.alert(`상위수수료율(${item.recommenderRollingRate}%)을 초과할 수 없습니다.`);
                                                                }
                                                            } else {
                                                                setUserInfo({
                                                                    ...userInfo,
                                                                    userRollingList: updatedList
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <span className={"text-sm text-blue-500"}>% (상위수수료율 : {item.recommenderRollingRate})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                        </div>

                        {/* 카지노 영역 */}
                        <GameSection 
                            title="카지노"
                            gameDiv="casino"
                            defaultRate={casinoDefaultRate}
                            setDefaultRate={setCasinoDefaultRate}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                        />

                        {/* 슬롯 영역 */}
                        <GameSection 
                            title="슬롯"
                            gameDiv="slot"
                            defaultRate={slotDefaultRate}
                            setDefaultRate={setSlotDefaultRate}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                        />
                    </div>
                </div>
            </div>

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={pointModal.visible}
                    onClose={() => {
                        setPointModal({visible: false, type: "0"})}}>
                <UserPointModal user={userInfo}
                                type={pointModal.type}
                                proc={(point) => {
                                    procPoint(point)
                                }}
                                close={() => {
                                    setPointModal({visible: false, type: "0"})
                                }}
                />
            </CModal>

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={moneyModal.visible}
                    onClose={() => {
                        setMoneyModal({visible: false, type: "0"})}}>
                <UserMoneyModal user={userInfo}
                                type={moneyModal.type}
                                proc={(money) => {
                                    procMoney(money)
                                }}
                                close={() => {
                                    setMoneyModal({visible: false, type: "0"})
                                }}
                />
            </CModal>

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={messageModal.visible}
                    onClose={() => {
                        setMessageModal({visible: false})}}>
                <UserMessageModal user={userInfo}
                                  proc={(message) => {
                                      procMessage(message)
                                  }}
                                  close={() => {
                                      setMessageModal({visible: false})
                                  }}
                />
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account} = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserDetail);