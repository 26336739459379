import React, {useEffect, useState} from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from './index'
import { webSocketService } from '../../../websocket/WebSocketService';
import {connect, useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {CContainer, CNav, CNavGroup, CNavItem} from "@coreui/react";
import {footerActions} from "../../../redux/actions/footer";
import Loading from "../../common/Loading"
import Navigation from "../../../_nav";
import { getHeader } from '../../../api/api';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// 로컬 스토리지에서 데이터 읽기 (만료 시간 확인 포함)
const getStorageWithExpiry = (key) => {
  try {
    const item = localStorage.getItem(key);
    if (!item) return null;

    const parsedItem = JSON.parse(item);
    // 만료 시간이 설정되어 있고, 현재 시간이 만료 시간을 초과한 경우
    if (parsedItem.expiry && new Date().getTime() > parsedItem.expiry) {
      localStorage.removeItem(key); // 만료된 데이터 삭제
      return null;
    }
    return parsedItem.value;
  } catch (error) {
    console.error('localStorage 읽기 오류:', error);
    // 오류 발생 시 항목 삭제
    localStorage.removeItem(key);
    return null;
  }
};

// 로컬 스토리지에 데이터 저장 (만료 시간 포함)
const setStorageWithExpiry = (key, value, expiryInMs = 7200000) => { // 기본 2시간
  try {
    const item = {
      value: value,
      expiry: new Date().getTime() + expiryInMs,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.error('localStorage 저장 오류:', error);
  }
};

const DefaultLayout = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {user} = useSelector((props) => props.account);
    const [counts, setCounts] = useState({
        depositCount: 0,
        withdrawCount: 0,
        userRegisterCount: 0,
        inquiryCount: 0
    });

    const menu = Navigation();
    const [loading, setLoading] = useState(false);
    const [userMenuList, setUserMenuList] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(menu[0]);

    // 웹소켓 메시지 핸들러
    const handleAdminMessage = (message) => {
        let audioFile = '';

        switch (message.type) {
            case 'USER_REGISTER':
                setCounts(prev => ({
                    ...prev,
                    userRegisterCount: message.waitingCount
                }));
                audioFile = 'Regist.mp3';
                break;

            case 'USER_CASH_DEPOSIT':
                setCounts(prev => ({
                    ...prev,
                    depositCount: message.waitingCount
                }));
                audioFile = 'Charge.mp3';
                break;

            case 'USER_CASH_WITHDRAW':
                setCounts(prev => ({
                    ...prev,
                    withdrawCount: message.waitingCount
                }));
                audioFile = 'Exchange.mp3';
                break;

            case 'INQUIRY':
                setCounts(prev => ({
                    ...prev,
                    inquiryCount: message.waitingCount
                }));
                audioFile = 'Inquiry.mp3';
                break;

            default:
                break;
        }

        // 오디오 재생 (audioFile이 설정된 경우에만)
        if (audioFile) {
            const audio = new Audio(process.env.PUBLIC_URL + `/assets/sounds/${audioFile}`);
            audio.play().catch(e => console.error('오디오 재생 실패:', e));
        }
    };

    useEffect(() => {
        if (user === undefined) {
            navigate("/login");
            return;
        }
        
        // 초기 헤더 카운트 정보 가져오기
        if (user && user.companyCode) {
            fetchHeaderCounts();
        }
        
        // 웹소켓 연결
        webSocketService.connect();
        // admin topic 구독
        webSocketService.subscribe('admin', handleAdminMessage);

        // URL 파라미터 처리 로직 수정
        const queryParams = new URLSearchParams(location.search);
        const paramUserId = queryParams.get('userId');
        
        if (paramUserId) {
            console.log('URL 파라미터에서 userId 발견:', paramUserId);
            // URL에서 추출한 userId를 로컬 스토리지에 저장
            localStorage.setItem('targetUserId', paramUserId);
        }

        // 마지막 방문 페이지가 있으면 해당 페이지로 이동 (만료 시간 확인)
        const lastVisitedPage = getStorageWithExpiry('lastVisitedPage');
        if (lastVisitedPage && location.pathname === '/dashboard') {
            // 대시보드 페이지에 있고 마지막 방문 페이지가 있는 경우에만 이동
            navigate(lastVisitedPage);
            
            // 메뉴 항목 찾기
            const findMenuItemByPath = (menuItems, path) => {
                for (const item of menuItems) {
                    if (item.to === path) {
                        return item;
                    }
                    if (item.items) {
                        const found = findMenuItemByPath(item.items, path);
                        if (found) return found;
                    }
                }
                return null;
            };
            
            // 전체 메뉴에서 해당 경로에 맞는 메뉴 찾기
            const menuItem = findMenuItemByPath(menu, lastVisitedPage);
            if (menuItem) {
                setSelectedMenu(menuItem);
            }
        }

        // 컴포넌트 언마운트 시 웹소켓 연결 해제 및 구독 해제
        return () => {
            webSocketService.unsubscribe('admin', handleAdminMessage);
            webSocketService.disconnect();
        };
    }, [user, navigate, location.pathname, location.search]);

    // 헤더 카운트 정보를 가져오는 함수
    const fetchHeaderCounts = async () => {
        try {
            const response = await getHeader({ companyCode: user.companyCode });
            if (response && response.data && response.data.success) {
                const headerData = response.data.data;
                setCounts({
                    depositCount: headerData.depositCount || 0,
                    withdrawCount: headerData.withdrawCount || 0,
                    userRegisterCount: headerData.userRegisterCount || 0,
                    inquiryCount: headerData.inquiryCount || 0
                });
            }
        } catch (error) {
            console.error('헤더 카운트 정보 가져오기 실패:', error);
        }
    };

    const pageMove = (obj) => {
        let menu = {}
        const userId = obj[0]; // userId 파라미터 추출
        const path = obj[1]; // 경로 파라미터 추출
        
        // UserDetail.js에서 사용할 수 있도록 userId를 localStorage에 저장
        if (userId) {
            localStorage.setItem('targetUserId', userId);
        }
        
        switch (path) {
            case "deposit":
                menu = {
                    id: 41,
                    component: CNavItem,
                    name: '입금 신청 내역',
                    to: `/deposit`,
                }
                break;
            case "withdraw":
                menu = {
                    id: 44,
                    component: CNavItem,
                    name: '출금 내역 조회',
                    to: `/withdraw`,
                }
                break;
            case "point":
                menu = {
                    id: 26,
                    component: CNavItem,
                    name: '포인트 관리',
                    to: `/point`,
                }
                break;
            case "loginLog":
                menu = {
                    id: 83,
                    component: CNavItem,
                    name: "로그인 로그",
                    to: `/loginLog`
                }
                break;
            case "betting":
                menu = {
                    id: 31,
                    component: CNavItem,
                    name: '통합 배팅내역',
                    to: `/betting`,
                }
                break;
            case "message/send":
                menu = {
                    id: 90,
                    component: CNavItem,
                    name: '쪽지 발송',
                    to: `/message/send`,
                }
                break;
            case "message/list":
                menu = {
                    id: 91,
                    component: CNavItem,
                    name: '쪽지 내역',
                    to: `/message/list`,
                }
                break;
            case "partner/change":
                menu = {
                    id: 92,
                    component: CNavItem,
                    name: '파트너 변경',
                    to: `/partner/change`,
                }
                break;
            case "statistics/daily":
                menu = {
                    id: 93,
                    component: CNavItem,
                    name: '일별 통계',
                    to: `/statistics/daily`,
                }
                break;
            case "user/history":
                menu = {
                    id: 94,
                    component: CNavItem,
                    name: '정보 수정 이력',
                    to: `/user/history`,
                }
                break;
        }
        goMenu(menu)
    }

    const goMenu = (menu) => {
        console.log('현재 경로:', location.pathname);
        console.log('이동할 경로:', menu.to);
        
        // 현재 경로와 이동할 경로가 같으면 localStorage에 저장하고 페이지 리로드
        if (location.pathname === menu.to) {
            // 마지막 방문 페이지 저장 (만료 시간 1시간)
            setStorageWithExpiry('lastVisitedPage', menu.to, 3600000); // 3600000ms = 1시간
            window.location.reload();
        } else {
            // 다른 페이지로 이동
            navigate(menu.to);
            setSelectedMenu(menu);
        }
    }

    // // 카운트 리셋 함수 추가
    // const resetCount = (countType) => {
    //     setCounts(prev => ({
    //         ...prev,
    //         [countType]: 0
    //     }));
    // };

    return (
        <>
            {user !== undefined &&
            <div>
                <AppSidebar setSelectedMenu={setSelectedMenu} userMenuList={userMenuList}/>
                <div className="wrapper d-flex flex-column min-vh-100">
                    <AppHeader 
                        move={goMenu} 
                        counts={counts}
                    />
                    <div className="body flex-grow-1 px-3">
                        <AppContent />
                    </div>
                </div>  
            </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        delFooterMenu: (menu) => {
            dispatch(footerActions.delFooterMenu(menu));
        },
        addFooterMenu: (menu) => {
            dispatch(footerActions.addFooterMenu(menu));
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
