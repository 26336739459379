import axios from "axios";
import {apiHost} from "../pages/common/Global";

const proxy = "";
// const proxy = "https://ziha-proxy-bef22eb1e8af.herokuapp.com/";

export function post(request, body) {
    return axios.post(proxy + request, body);
}

export function postQueryString(request, config) {
    return axios.post(proxy + request, null, config);
}

export function get(request, body) {
    return axios.get(proxy + request, {
        params: body
    });
}

export function put(request, body, config) {
    return config ? axios.put(proxy + request, body, config) : axios.put(proxy + request, body);
}

export function patch(request, body) {
    return axios.patch(proxy + request, body);
}

export function deleteM(request, body) {
    return axios.delete(request, {data: body});
}

export function login(params) {
    const request = apiHost + "/api/v1/login/";
    return post(request, params);
}

export function getGUserPartnerList() {
    const request = apiHost + "/api/v1/partner/list";
    return get(request);
}

export function getGUser(userId) {
    const request = apiHost + "/api/v1/user/" + userId;
    return get(request);
}

export function getGUserList(params) {
    const request = apiHost + "/api/v1/user/list";
    return post(request, params);
}

export function getGUserListByUserType(userType) {
    const request = apiHost + "/api/v1/user/userType/" + userType;
    return get(request);
}

export function getGUserListByAccept() {
    const request = apiHost + "/api/v1/user/accept/list";
    return get(request);
}

export function putGUser(gUser) {
    const request = apiHost + "/api/v1/user/";
    return put(request, gUser);
}

export function putDeposit(deposit) {
    const request = apiHost + "/api/v1/user/cash/deposit";
    return put(request, deposit)
}

export function getRequestDepositList(params) {
    const request = apiHost + "/api/v1/user/cash/deposit/request/list";
    return post(request, params);
}

export function getDepositList(params) {
    const request = apiHost + "/api/v1/user/cash/deposit/list";
    return post(request, params);
}

export function getUserCashByAdmin(params) {
    const request = apiHost + "/api/v1/user/cash/admin/list";
    return post(request, params);
}

export function putWithdraw(withdraw) {
    const request = apiHost + "/api/v1/user/cash/withdraw"
    return put(request, withdraw)
}

export function getRequestWithdrawList(params) {
    const request = apiHost + "/api/v1/user/cash/withdraw/request/list"
    return post(request, params)
}

export function getWithdrawList(params) {
    const request = apiHost + "/api/v1/user/cash/withdraw/list"
    return post(request, params)
}

export function getDashboard(params) {
    const request = apiHost + "/api/v1/game/dashboard/today"
    return post(request, params)
}

export function getHeader(params) {
    const request = apiHost + "/api/v1/game/dashboard"
    return post(request, params)
}

// 게시판
export function getBoardList(boardId) {
    const request = apiHost + "/api/v1/board/" + boardId
    return get(request)
}

export function postBoard(notice) {
    const request = apiHost + "/api/v1/board/post"
    return put(request, notice)
}

// 설정
export function getSiteSetting(params) {
    const request = apiHost + "/api/v1/setting"
    return post(request, params)
}

// 사이트 옵션 저장
export function putSiteServiceOptionList(params) {
    const request = apiHost + "/api/v1/setting/service/options"
    return put(request, params)
}

// 메신저 설정 저장
export function putMessengerList(params) {
    const request = apiHost + "/api/v1/setting/messenger"
    return put(request, params)
}

export function getSiteConfig(siteId) {
    const request = apiHost + "/api/v1/config/site/" + siteId
    return get(request)
}

export function putSiteConfig(config) {
    const request = apiHost + "/api/v1/config/site"
    return put(request, config)
}

export function getSiteConfigUserLevel(siteId, userLevel) {
    const request = apiHost + "/api/v1/config/site/" + siteId + "/" + userLevel
    return get(request)
}

export function putSiteConfigUserLevel(siteLevelConfig) {
    const request = apiHost + "/api/v1/config/site/level"
    return put(request, siteLevelConfig)
}

// 점검 설정
export function getSiteInspection(siteId) {
    const request = apiHost + "/api/v1/config/site/inspection/" + siteId
    return get(request)
}

export function putSiteInspection(inspect) {
    const request = apiHost + "/api/v1/config/site/inspection"
    return put(request, inspect)
}

// 경기 종목 조회
export function getSportsBaseList() {
    const request = apiHost + "/api/v1/sports/base/list";
    return get(request)
}

// 사용자별 경기 설정
export function putSportsSetting(setting) {
    const request = apiHost + "/api/v1/user/sports/setting"
    return put(request, setting)
}

// 사용자별 요율 설정
export function putSportsPer(sportsPer) {
    const request = apiHost + "/api/v1/user/sports/per"
    return put(request, sportsPer);
}

// 보유금 처리
export function postMoney(money) {
    const request = apiHost + "/api/v1/cash/post"
    return post(request, money)
}

export function postPoint(point) {
    const request = apiHost + "/api/v1/point/post"
    return post(request, point)
}

// 로그인 이력
export function loginHistory(params) {
    const request = apiHost + "/api/v1/login/history"
    return post(request, params)
}

// 베팅이력
export function getBettingList(params) {
    const request = apiHost + "/api/v1/betting/list"
    return post(request, params)
}

export function getMinigameBettingList(params) {
    const request = apiHost + "/api/v1/betting/minigame/list"
    return post(request, params)
}

export function getCasinoBettingList(params) {
    const request = apiHost + "/api/v1/betting/casino/list"
    return post(request, params)
}

export function getSlotBettingList(params) {
    const request = apiHost + "/api/v1/betting/slot/list"
    return post(request, params)
}

export function putSportScore(score) {
    const request = apiHost + "/api/v1/sports/score"
    return put(request, score)
}

export function getBettingDetailList(bettingId) {
    const request = apiHost + "/api/v1/betting/detail/" + bettingId
    return get(request)
}

/**
 * 통계 정보
 */
export function getUserStatistics(params) {
    const request = apiHost + "/api/v1/report/user/statistics"
    return post(request, params)
}

export function getGameStatistics(params) {
    const request = apiHost + "/api/v1/report/game/statistics"
    return post(request, params)
}

export function getDailyStatistics(params) {
    const request = apiHost + "/api/v1/report/daily/statistics"
    return post(request, params)
}

/**
 * 포인트 이력
 */
export function getUserPointList(params) {
    const request = apiHost + "/api/v1/user/point/list"
    return post(request, params)
}

/**
 * 머니이력
 */
export function getUserCashList(params) {
    const request = apiHost + "/api/v1/cash/list"
    return post(request, params)
}

/**
 * 회원 롤링정보
 */
export function putUserRolling(params) {
    const request = apiHost + "/api/v1/user/rolling"
    return put(request, params)
}

/**
 * 스포츠 설정 조회
 */
export function getSportsConfig(params) {
    const request = apiHost + "/api/v1/sports/config/list"
    return post(request, params)
}

export function getCompanyBaseSetting(companyCode) {
    const request = apiHost + "/api/v1/sports/setting/" + companyCode
    return get(request)
}

export function putCompanyBaseSetting(setting) {
    const request = apiHost + "/api/v1/sports/setting/base"
    return put(request, setting)
}

export function putCompanySportJoint(joint) {
    const request = apiHost + "/api/v1/sports/setting/joint"
    return put(request, joint)
}

// 미니게임
export function getDhPowerballResultList(params) {
    const request = apiHost + "/api/v1/minigame/powerball/results"
    return post(request, params);
}

export function getDhPowerLadderResultList(params) {
    const request = apiHost + "/api/v1/minigame/powerladder/results"
    return post(request, params);
}

export function updateDhPowerballResult(result) {
    const request = apiHost + "/api/v1/minigame/powerball/result"
    return put(request, result);
}

export function updateDhPowerLadderResult(result) {
    const request = apiHost + "/api/v1/minigame/powerladder/result"
    return put(request, result);
}

// 베팅 취소
export function cancelBetting(betting) {
    const request = apiHost + "/api/v1/betting/cancel/admin"
    return put(request, betting)
}

/**
 * 리그관리
 */
export function getCompanyLeagueSport(params) {
    const companyCode = params.companyCode;
    const sport = params.sport;

    const request = apiHost + "/api/v1/sports/league/company/"+companyCode+"/sport/"+sport+"/separated";
    return get(request)
}

export function setCompanyLeague(companyCode, leagues, userName) {
    const request = apiHost + "/api/v1/sports/league/company/"+companyCode+"/bulk";
    return put(request, leagues, {params: {userName: userName}});
}

/**
 * 쪽지관리
 */
export function sendNote(noteData, headers) {
    const request = apiHost + "/api/v1/user-notes/send";
    return axios.post(request, noteData, { headers });
}

/**
 * 사용자의 쪽지 목록 조회
 */
export function getNoteList(companyCode, userId, page, size) {
    const request = apiHost + "/api/v1/user-notes/list";
    return get(request, {
        companyCode: companyCode,
        userId: userId,
        page: page || 0,
        size: size || 10
    });
}

/**
 * 쪽지 상세 조회
 */
export function getNoteDetail(seq) {
    const request = apiHost + "/api/v1/user-notes/" + seq;
    return get(request);
}

/**
 * 쪽지 삭제 (논리적 삭제)
 */
export function deleteNote(seq, userId) {
    const request = apiHost + "/api/v1/user-notes/" + seq;
    return deleteM(request, { userId: userId });
}