import {connect, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import Loading from "../../../common/Loading";
import {AgGridReact} from "ag-grid-react";
import * as Global from "../../../common/Global";
import InputDate from "../../../common/InputDate";
import InputTextGroup from "../../../common/InputTextGroup";
import {
    getDhPowerballResultList,
    getDhPowerLadderResultList,
    updateDhPowerballResult,
    updateDhPowerLadderResult
} from "../../../../api/api";
import dayjs from 'dayjs';

const StartPositionRenderer = (props) => {
    const options = [
        { value: 'LEFT', label: '좌출' },
        { value: 'RIGHT', label: '우출' }
    ];

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (props.node.setDataValue) {
            props.node.setDataValue(props.column.colId, newValue);
        }
    };

    return (
        <select
            value={props.value || ''}
            onChange={handleChange}
            className="w-full h-full px-2 border-0 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
            <option value="">선택</option>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

const LineNumberRenderer = (props) => {
    const options = [
        { value: '3', label: '3줄' },
        { value: '4', label: '4줄' }
    ];

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (props.node.setDataValue) {
            props.node.setDataValue(props.column.colId, newValue);
        }
    };

    return (
        <select
            value={props.value || ''}
            onChange={handleChange}
            className="w-full h-full px-2 border-0 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
            <option value="">선택</option>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

const OddEvenRenderer = (props) => {
    const options = [
        { value: 'ODD', label: '홀' },
        { value: 'EVEN', label: '짝' }
    ];

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (props.node.setDataValue) {
            props.node.setDataValue(props.column.colId, newValue);
        }
    };

    return (
        <select
            value={props.value || ''}
            onChange={handleChange}
            className="w-full h-full px-2 border-0 focus:outline-none focus:ring-1 focus:ring-blue-500"
        >
            <option value="">선택</option>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

const DhPowerLadderResult = (props) => {
    const {user} = useSelector((props) => props.account);
    const gridRef = useRef()
    const [loading, setLoading] = useState(false)
    const [searchForm, setSearchForm] = useState({
        gameDt: dayjs(), gameRound: ""
    })
    const [dataList, setDataList] = useState([])

    const [columnDefs] = useState([
        {field: 'round', headerName: '경기순번', width: 80},
        {field: 'gameDt', headerName: '경기날짜', width: 160, cellClass: "static-cell", valueFormatter: (params) => {
                return params.value ? dayjs(params.value, 'YYYYMMDD').format('YYYY-MM-DD') : '';
            }},
        {field: 'instTime', headerName: "경기시간", width: 100, cellClass: "static-cell", valueFormatter: (params) => {
                return params.value ? dayjs(params.value, 'YYYYMMDDHHmmss').format('HH:mm:ss') : '';
            }},
        {field: 'gameRound', headerName: "당일회차", width: 100},
        {
            field: 'plStart',
            headerName: "출발",
            width: 100,
            cellRenderer: StartPositionRenderer,
            cellStyle: { padding: '0' }
        },
        {
            field: 'plLine',
            headerName: "줄수",
            width: 100,
            cellRenderer: LineNumberRenderer,
            cellStyle: { padding: '0' }
        },
        {
            field: 'plOddEven',
            headerName: "홀짝",
            width: 100,
            cellRenderer: OddEvenRenderer,
            cellStyle: { padding: '0' }
        },
        {width: 100, cellRenderer: e => {
                return <button className={"rounded px-2 py-1 text-xs bg-blue-500 text-white font-bold"} onClick={() => updateResult(e)}>결과변경</button>
            }},
    ])

    useEffect(() => {
        search()
    }, [])

    const handleDate = (date) => {
        setSearchForm({
            ...searchForm,
            gameDt: date
        })
    }

    const handleWhere = (e) => {
        const {name, value} = e.target;
        setSearchForm({
            ...searchForm,
            [name]: value
        })
    }

    const search = () => {
        const params = {
            ...searchForm,
            gameDt: searchForm.gameDt.format("YYYYMMDD")
        }

        setLoading(true)
        getDhPowerLadderResultList(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setDataList(data)
            }
        })
            .catch(ex => {})
            .finally(() => setLoading(false))
    }

    const updateResult = (params) => {
        if (window.confirm("결과처리를 변경하시겠어요?")) {
            const rowData = params.node.data;

            const request = {
                round: rowData.round,
                gameDt: rowData.gameDt,
                gameRound: rowData.gameRound,
                plStart: rowData.plStart,
                plLine: rowData.plLine,
                plOddEven: rowData.plOddEven
            }

            // API 호출
            setLoading(true)
            updateDhPowerLadderResult(request)
                .then(result => {
                    const {status} = result;
                    if (status === 200) {
                        alert('결과가 성공적으로 변경되었습니다.');
                        search();  // 데이터 리로드
                    }
                })
                .catch(ex => {
                    console.error('결과 변경 중 오류 발생:', ex);
                    alert('결과 변경 중 오류가 발생했습니다.');
                })
                .finally(() => setLoading(false))
        }
    };

    return (
        <div className={"flex flex-col"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>{"미니게임 관리 > 동행파워사다리 관리 > 경기 결과"}</p>
            <div className={"flex flex-row p-2 gap-1 items-center justify-between bg-white border mt-1"}>
                <div className={"flex flex-row p-2 gap-4"}>
                    <InputDate title={"경기날짜"} startTime={searchForm.gameDt} setValue={handleDate} />
                    <InputTextGroup title={"경기회차"} name={"gameRound"} value={searchForm.gameRound} setValue={handleWhere} />
                    <button className={"bg-o2-blue px-3 rounded text-white text-sm"} onClick={search}>조회</button>
                </div>
            </div>
            <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 600}}>
                <AgGridReact
                    ref={gridRef}
                    headerHeight={40}
                    rowData={dataList}
                    pagination={true}
                    defaultColDef={Global.defaultColDef}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(DhPowerLadderResult);