import React from "react";
import {DatePicker} from "antd";
import 'dayjs/locale/ko';
import locale from "antd/es/date-picker/locale/ko_KR";

const InputDate = (props) => {
    const handleInput = (e) => {
        props.setValue(e);
    }

    return (
        <div className={"flex flex-row gap-2 items-center"}>
            <span className={"text-sm font-bold text-nowrap"}>{props.title}</span>
            <DatePicker className={"h-6 px-2 text-sm"}
                         picker={"date"}
                         placeholder={""}
                         locale={locale}
                         value={props.startTime}
                         onChange={handleInput}/>
        </div>
    )
}

export default InputDate;