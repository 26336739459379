import {connect, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import Loading from "../../../common/Loading";
import {AgGridReact} from "ag-grid-react";
import * as Global from "../../../common/Global";
import InputDate from "../../../common/InputDate";
import InputTextGroup from "../../../common/InputTextGroup";
import {getDhPowerballResultList, updateDhPowerballResult} from "../../../../api/api";
import dayjs from 'dayjs';

const InputCellRenderer = (props) => {
    const [value, setValue] = useState(props.value);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (props.node.setDataValue) {
            props.node.setDataValue(props.column.colId, newValue);
        }
    };

    return (
        <input
            value={value || ''}
            onChange={handleChange}
            className="w-full h-full px-2 border-0 focus:outline-none focus:ring-1 focus:ring-blue-500"
            maxLength={1}
        />
    );
};

const BallNumbersRenderer = (props) => {
    const [value, setValue] = useState(props.value);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (props.node.setDataValue) {
            props.node.setDataValue(props.column.colId, newValue);
            props.api.refreshCells({
                force: true,
                columns: ['ballSum']
            });
        }
    };

    return (
        <input
            type="text"
            value={value || ''}
            onChange={handleChange}
            className="w-full h-full px-2 border-0 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="예: 1,2,3,4,5"
        />
    );
};

const DhPowerBallResult = (props) => {
    const {user} = useSelector((props) => props.account);
    const gridRef = useRef()
    const [loading, setLoading] = useState(false)
    const [searchForm, setSearchForm] = useState({
        gameDt: dayjs(), gameRound: ""
    })
    const [dataList, setDataList] = useState([])

    const [columnDefs] = useState([
        {field: 'round', headerName: '경기순번', width: 80},
        {field: 'gameDt', headerName: '경기날짜', width: 160, cellClass: "static-cell", valueFormatter: (params) => {
            return params.value ? dayjs(params.value, 'YYYYMMDD').format('YYYY-MM-DD') : '';
        }},
        {field: 'instTime', headerName: "경기시간", width: 100, cellClass: "static-cell", valueFormatter: (params) => {
            return params.value ? dayjs(params.value, 'YYYYMMDDHHmmss').format('HH:mm:ss') : '';
        }},
        {field: 'gameRound', headerName: "당일회차", width: 100},
        {
            field: 'pbNumber',
            headerName: "파워볼",
            width: 100,
            cellRenderer: InputCellRenderer,
            cellStyle: { padding: '0' }
        },
        {
            field: 'ballNumbers',
            headerName: "일반볼",
            width: 200,
            cellRenderer: BallNumbersRenderer,
            cellStyle: { padding: '0' }
        },
        {
            field: 'ballSum',
            headerName: "일반볼 합",
            width: 100,
            valueGetter: (params) => {
                if (!params.data.ballNumbers) return '';
                
                const numbers = params.data.ballNumbers.split(',')
                    .map(num => num.trim())
                    .filter(num => num !== '')
                    .map(num => parseInt(num));
                
                if (numbers.some(isNaN)) return '';
                
                return numbers.reduce((sum, num) => sum + num, 0);
            }
        },
        {width: 100, cellRenderer: e => {
            return <button className={"rounded px-2 py-1 text-xs bg-blue-500 text-white font-bold"} onClick={() => updateResult(e)}>결과변경</button>
        }},
    ])

    useEffect(() => {
        search()
    }, [])

    const handleDate = (date) => {
        setSearchForm({
            ...searchForm,
            gameDt: date
        })
    }

    const handleWhere = (e) => {
        const {name, value} = e.target;
        setSearchForm({
            ...searchForm,
            [name]: value
        })
    }

    const search = () => {
        const params = {
            ...searchForm,
            gameDt: searchForm.gameDt.format("YYYYMMDD")
        }

        setLoading(true)
        getDhPowerballResultList(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setDataList(data)
            }
        })
            .catch(ex => {})
            .finally(() => setLoading(false))
    }

    const updateResult = (params) => {
        if (window.confirm("결과처리를 변경하시겠어요?")) {
            const rowData = params.node.data;

            const request = {
                round: rowData.round,
                gameDt: rowData.gameDt,
                gameRound: rowData.gameRound,
                ballNumbers: rowData.ballNumbers,
                pbNumber: rowData.pbNumber
            }
            console.log("### params :: ", request)

            // API 호출
            setLoading(true)
            updateDhPowerballResult(request)
                .then(result => {
                    const {status} = result;
                    if (status === 200) {
                        alert('결과가 성공적으로 변경되었습니다.');
                        search();  // 데이터 리로드
                    }
                })
                .catch(ex => {
                    console.error('결과 변경 중 오류 발생:', ex);
                    alert('결과 변경 중 오류가 발생했습니다.');
                })
                .finally(() => setLoading(false))
        }
    };

    return (
        <div className={"flex flex-col"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>{"미니게임 관리 > 동행파워볼 관리 > 경기 결과"}</p>
            <div className={"flex flex-row p-2 gap-1 items-center justify-between bg-white border mt-1"}>
                <div className={"flex flex-row p-2 gap-4"}>
                    <InputDate title={"경기날짜"} startTime={searchForm.gameDt} setValue={handleDate} />
                    <InputTextGroup title={"경기회차"} name={"gameRound"} value={searchForm.gameRound} setValue={handleWhere} />
                    <button className={"bg-o2-blue px-3 rounded text-white text-sm"} onClick={search}>조회</button>
                </div>
            </div>
            <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 600}}>
                <AgGridReact
                    ref={gridRef}
                    headerHeight={40}
                    rowData={dataList}
                    pagination={true}
                    defaultColDef={Global.defaultColDef}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(DhPowerBallResult);