import {useState} from "react";
import ic_close from "../../../assets/ic-close.svg";

const UserMessageModal = (props) => {
    const [subject, setSubject] = useState("")
    const [contents, setContents] = useState("")

    const post = () => {
        if (!subject || subject.trim() === "") {
            window.alert("제목을 입력해주세요.");
            return;
        }

        if (!contents || contents.trim() === "") {
            window.alert("내용을 입력해주세요.");
            return;
        }

        if (window.confirm("쪽지를 전송하시겠습니까?")) {
            const messageBody = {
                subject: subject,
                contents: contents
            }
            props.proc(messageBody)
            props.close()
        }
    }

    return (
        <div className={"bg-gray-800 py-[30px] rounded"}>
            <div className={"flex flex-row justify-end px-[36px]"} onClick={props.close}>
                <img src={ic_close} alt={""}/>
            </div>
            <p className={"text-white text-center text-4xl font-bold"}>쪽지 전송</p>
            <div className={"flex flex-col px-8 mt-8 gap-4"}>
                <div className={"flex flex-col text-white text-sm gap-2"}>
                    <label htmlFor="subject" className="text-white">제목</label>
                    <input
                        id="subject"
                        name="subject"
                        type="text"
                        value={subject}
                        onChange={event => {
                            setSubject(event.target.value)
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className={"flex flex-col text-white text-sm gap-2"}>
                    <label htmlFor="contents" className="text-white">내용</label>
                    <textarea
                        id="contents"
                        name="contents"
                        value={contents}
                        onChange={event => {
                            setContents(event.target.value)
                        }}
                        required
                        rows="6"
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <button className={"rounded mt-4 p-2 bg-blue-500 text-lg text-white font-bold"}
                        onClick={post}>
                    전송
                </button>
            </div>
        </div>
    )
}

export default UserMessageModal 