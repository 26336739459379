import React from "react";
import DhPowerBallResult from "./pages/domain/minigame/dhpowerball/DhPowerBallResult";
import MinigameBettingList from "./pages/domain/betting/MinigameBettingList";
import CasinoBettingList from "./pages/domain/betting/CasinoBettingList";
import SlotBettingList from "./pages/domain/betting/SlotBettingList";
import DhPowerLadderResult from "./pages/domain/minigame/dhpowerladder/DhPowerLadderResult";

const Dashboard = React.lazy(() => import("./pages/domain/dashboard/Dashboard"))
const PartnerList = React.lazy(() => import("./pages/domain/partner/PartnerList"))
const PartnerList2 = React.lazy(() => import("./pages/domain/partner/PartnerList2"))
const PartnerCreate = React.lazy(() => import("./pages/domain/partner/PartnerCreate"))
const UserCreate = React.lazy(() => import("./pages/domain/user/UserCreate"))
const UserList = React.lazy(() => import("./pages/domain/user/UserList"))
const UserListPartner = React.lazy(() => import("./pages/domain/user/UserListPartner"))
const UserAccept = React.lazy(() => import("./pages/domain/user/UserAccept"))
const CashHistory = React.lazy(() => import("./pages/domain/user/CashHistory"))
const UserPointList = React.lazy(() => import("./pages/domain/user/UserPointList"))
const UserHistory = React.lazy(() => import("./pages/domain/user/UserHistory"))
const LoginHistory = React.lazy(() => import("./pages/domain/user/LoginHistory"))
const LoginUserList = React.lazy(() => import("./pages/domain/user/LoginUserList"))
const UserMultiIpList = React.lazy(() => import("./pages/domain/user/UserMultiIpList"))
const BettingList = React.lazy(() => import("./pages/domain/betting/BettingList"))
const CashInRequestList = React.lazy(() => import("./pages/domain/cash/CashInRequestList"))
const CashInList = React.lazy(() => import("./pages/domain/cash/CashInList"))
const CashWithdrawRequestList = React.lazy(() => import("./pages/domain/cash/CashWithdrawRequestList"))
const CashWithdrawList = React.lazy(() => import("./pages/domain/cash/CashWithdrawList"))
const CashAdminProcList = React.lazy(() => import("./pages/domain/cash/CashAdminProcList"))
const CashChangeHistory = React.lazy(() => import("./pages/domain/cash/CashChangeHistoryList"))
const SettlePartners = React.lazy(() => import("./pages/domain/settle/SettlePartners"))
const SettlePartners2 = React.lazy(() => import("./pages/domain/settle/SettlePartners2"))
const SettleUsers = React.lazy(() => import("./pages/domain/settle/SettleUsers"))
const SettleGames = React.lazy(() => import("./pages/domain/settle/SettleGames"))
const SettlePeriod = React.lazy(() => import("./pages/domain/settle/SettlePeriod"))
const SettleMonthly = React.lazy(() => import("./pages/domain/settle/SettleMonthly"))
const GameSportsList = React.lazy(() => import("./pages/domain/games/GameSportsList"))
const GameSettingManage = React.lazy(() => import("./pages/domain/games/GameSettingManage"))
const CustomerMemo = React.lazy(() => import("./pages/domain/board/CustomerMemoList"))
const CustomerOneToOne = React.lazy(() => import("./pages/domain/board/CustomerOneToOneList"))
const CustomerNotice = React.lazy(() => import("./pages/domain/board/CustomerNoticeList"))
const CustomerFaq = React.lazy(() => import("./pages/domain/board/CustomerFaqList"))
const ConfigEvent = React.lazy(() => import("./pages/domain/config/ConfigEvent"))
const ConfigBank = React.lazy(() => import("./pages/domain/config/ConfigBank"))
const ConfigBankGrade = React.lazy(() => import("./pages/domain/config/ConfigBankGrade"))
const ConfigDomain = React.lazy(() => import("./pages/domain/config/ConfigDomain"))
const ConfigPopup = React.lazy(() => import("./pages/domain/config/ConfigPopup"))
const ConfigInspection = React.lazy(() => import("./pages/domain/config/ConfigInspection"))
const ConfigOption = React.lazy(() => import("./pages/domain/config/ConfigOption"))
const ConfigBlock = React.lazy(() => import("./pages/domain/config/ConfigBlock"))
const AdminList = React.lazy(() => import("./pages/domain/admin/AdminList"))
const AdminJoinIpList = React.lazy(() => import("./pages/domain/admin/AdminJoinIp"))
const AdminLogs = React.lazy(() => import("./pages/domain/admin/AdminLogs"))
const SiteSetting = React.lazy(() => import("./pages/domain/config/SiteSetting"))
const ConfigSiteUserLevel = React.lazy(() => import("./pages/domain/config/LevelSetting"))
const LogViewer = React.lazy(() => import("./pages/domain/config/LogViewer"))
const MessageSend = React.lazy(() => import("./pages/domain/message/MessageSend"))
const MessageList = React.lazy(() => import("./pages/domain/message/MessageList"))
const PartnerChange = React.lazy(() => import("./pages/domain/partner/PartnerChange"))
const StatisticsDaily = React.lazy(() => import("./pages/domain/statistics/StatisticsDaily"))

const routes = [
	{path: "/", element: Dashboard},
	{path: "/dashboard", element: Dashboard},
	{path: "/partner/list", element: PartnerList},
	{path: "/partner/list2", element: PartnerList2},
	{path: "/partner/create", element: PartnerCreate},
	{path: "/user/create", element: UserCreate},
	{path: "/user/list", element: UserList},
	{path: "/user/list-partner", element: UserListPartner},
	{path: "/user/accept", element: UserAccept},
	{path: "/user/cash-history", element: CashHistory},
	{path: "/user/point", element: UserPointList},
	{path: "/user/login/history", element: LoginHistory},
	{path: "/user/history", element: UserHistory},
	{path: "/user/login-user", element: LoginUserList},
	{path: "/user/multi-ip", element: UserMultiIpList},
	{path: "/cash/in-request", element: CashInRequestList},
	{path: "/cash/in-list", element: CashInList},
	{path: "/cash/withdraw-request", element: CashWithdrawRequestList},
	{path: "/cash/withdraw-list", element: CashWithdrawList},
	{path: "/cash/admin", element: CashAdminProcList},
	{path: "/cash/change-history", element: CashChangeHistory},
	{path: "/settle/partner-list1", element: SettlePartners},
	{path: "/settle/partner-list2", element: SettlePartners2},
	{path: "/settle/user-list", element: SettleUsers},
	{path: "/settle/game-list", element: SettleGames},
	{path: "/settle/daily", element: SettlePeriod},
	{path: "/settle/monthly", element: SettleMonthly},
	{path: "/game/sports", element: GameSportsList},
	{path: "/game/setting", element: GameSettingManage},
	{path: "/customer/memo", element: CustomerMemo},
	{path: "/customer/inquiry", element: CustomerOneToOne},
	{path: "/customer/notice", element: CustomerNotice},
	{path: "/customer/faq", element: CustomerFaq},
	{path: "/config/event", element: ConfigEvent},
	{path: "/config/default-bank", element: ConfigBank},
	{path: "/config/bank-grade", element: ConfigBankGrade},
	{path: "/config/domain", element: ConfigDomain},
	{path: "/config/inspection", element: ConfigInspection},
	{path: "/config/option", element: ConfigOption},
	{path: "/config/popup", element: ConfigPopup},
	{path: "/config/block", element: ConfigBlock},
	{path: "/admin/list", element: AdminList},
	{path: "/admin/ip-list", element: AdminJoinIpList},
	{path: "/admin/logs", element: AdminLogs},
	{path: "/config/site", element: SiteSetting},
	{path: "/config/level", element: ConfigSiteUserLevel},
	{path: "/config/log", element: LogViewer},

	// 베팅내역
	{path: "/betting/list", element: BettingList},
	{path: "/betting/minigame/list", element: MinigameBettingList},
	{path: "/betting/casino/list", element: CasinoBettingList},
	{path: "/betting/slot/list", element: SlotBettingList},

	// 미니게임
	{path: "/minigame/dhpowerball/result", element: DhPowerBallResult},
	{path: "/minigame/dhpowerladder/result", element: DhPowerLadderResult},

	// 새로운 경로들
	{path: "/deposit", element: CashInList},
	{path: "/withdraw", element: CashWithdrawList},
	{path: "/point", element: UserPointList},
	{path: "/message/send", element: MessageSend},
	{path: "/message/list", element: MessageList},
	{path: "/partner/change", element: PartnerChange},
	{path: "/statistics/daily", element: StatisticsDaily}
]

export default routes;