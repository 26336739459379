const INIT_ACCOUNT = "@@INIT_ACCOUNT";
const SET_ACCOUNT = "@@SET_ACCOUNT";

// 로컬 스토리지에서 데이터 읽기 (만료 시간 확인 포함)
const getStorageWithExpiry = (key) => {
  try {
    const item = localStorage.getItem(key);
    if (!item) return null;

    const parsedItem = JSON.parse(item);
    // 만료 시간이 설정되어 있고, 현재 시간이 만료 시간을 초과한 경우
    if (parsedItem.expiry && new Date().getTime() > parsedItem.expiry) {
      localStorage.removeItem(key); // 만료된 데이터 삭제
      return null;
    }
    return parsedItem.value;
  } catch (error) {
    console.error('localStorage 읽기 오류:', error);
    // 오류 발생 시 항목 삭제
    localStorage.removeItem(key);
    return null;
  }
};

// 로컬 스토리지에 데이터 저장 (만료 시간 포함)
const setStorageWithExpiry = (key, value, expiryInMs = 7200000) => { // 기본 2시간
  try {
    const item = {
      value: value,
      expiry: new Date().getTime() + expiryInMs,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.error('localStorage 저장 오류:', error);
  }
};

const initialState = {
  user: getStorageWithExpiry('user')
};

function initAccount() {
  // 로그아웃 시 localStorage에서 사용자 정보 제거
  localStorage.removeItem('user');
  return {
    type: INIT_ACCOUNT
  };
}

function applyInitAccount() {
  return {
    user: undefined
  };
}

function setAccount(user) {
  // 로그인 성공 시 localStorage에 사용자 정보 저장 (만료 시간 2시간)
  setStorageWithExpiry('user', user, 7200000); // 7200000ms = 2시간
  return {
    type: SET_ACCOUNT,
    user
  };
}

function applySetAccount(state, action) {
  const { user } = action;
  return {
    ...state,
    user
  };
}

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case INIT_ACCOUNT:
      return applyInitAccount();
    case SET_ACCOUNT:
      return applySetAccount(state, action);
  }
}

const actionCreator = {
  initAccount,
  setAccount
};

export { actionCreator as accountActions };

export default reducer;
