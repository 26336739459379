import {connect, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Loading from "../../common/Loading";
import * as api from "../../../api/api";
import {AgGridReact} from "ag-grid-react";
import * as Global from "../../common/Global";
import {CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import BettingDetail from "./BettingDetail";
import dayjs from "dayjs";
import InputRangeDate from "../../common/InputRangeDate";
import {getMinigameBettingList} from "../../../api/api";

const SlotBettingList = (props) => {
    const vendorName = (vendorCode) => {
        switch (vendorCode) {
            case "pragmatic_slot":
                return "프라그마틱 슬롯"
            case "pragmatic_casino" :
                return "프라그마틱 카지노"
        }
    }

    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [searchForm, setSearchForm] = useState({
        fromDate: dayjs().add(-1, 'day'), toDate: dayjs()
    })
    const [dataList, setDataList] = useState([])
    const [modal, setModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})

    const [columnDefs] = useState([
        {field: 'No', headerName: 'No', width: 40, valueGetter: 'node.rowIndex + 1', cellClass: 'static-cell'},
        {field: 'seq', headerName: 'No', width: 40, hide: true},
        {
            field: 'bettingUserId',
            headerName: "아이디",
            width: 180,
            cellStyle: { cursor: 'pointer', color: '#3B82F6', fontWeight: 'bold' },
            valueFormatter: (params) => {
                if (!params.data) return '';
                return `${params.data.bettingUserId} (${params.data.nickName || ''})`;
            }
        },
        {field: 'vendorCode', headerName: "종목", width: 180, valueFormatter: (params) => {
                return vendorName(params.data.vendorCode)
            },
            cellClass: 'static-cell'},
        {field: 'gameName', headerName: '게임', width: 200},
        {
            field: 'createdAt',
            headerName: "배팅시각",
            width: 180,
            cellClass: 'static-cell',
            valueFormatter: (params) => {
                return params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : '';
            }
        },
        {field: 'amount', headerName: "배팅금", width: 100, valueFormatter: Global.currencyFormatter, cellClass: 'number-cell'},
        {field: 'winAmount', headerName: "당첨금", width: 100, valueFormatter: Global.currencyFormatter, cellClass: 'number-cell'},
        {
            field: 'status',
            headerName: "승패",
            width: 100,
            cellClass: 'static-cell',
            valueGetter: e => {
                if (e.data.status === 0) return '대기'
                else if (e.data.status === 1) return '적중'
                else if (e.data.status === 2) return '실격'
            },
            cellStyle: params => {
                if (params.data.status === 1) {
                    return { color: 'green' };
                } else if (params.data.status === 2) {
                    return { color: 'red' };
                }
                return null;
            }
        },
        {field: 'transactionCode', headerName: "검증코드", width: 300}
    ])

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        const params = {
            companyCode: user.companyCode,
            fromDate: searchForm.fromDate.format("YYYYMMDD"),
            toDate: searchForm.toDate.format("YYYYMMDD"),
            vendorTypeCode: "slot"
        }
        setLoading(true)
        api.getSlotBettingList(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                console.log("### data :: ", data)
                setDataList(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onCellClicked = (params) => {
        // 클릭된 열(column)에 따라 다른 처리
        switch(params.column.colId) {
            case 'bettingUserId':
                // 유저 ID 클릭 시 처리
                handleUserClick(params.data);
                break;
        }
    };

    const handleUserClick = (data) => {
        const params = "userId=" + data.bettingUserId + "&procUserId=" + user.userId
        window.open('/user/detail?' + params, "_user-detail", "width=1500, height=800");
    }

    const handleDate = (date) => {
        setSearchForm({
            ...searchForm,
            fromDate: date[0],
            toDate: date[1]
        })
    }

    return (
        <div className={"flex flex-col"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>{"배팅 내역 > 슬롯 배팅내역"}</p>
            <div className={"bg-gray-100 border mt-1"}>
                <div className={"flex flex-row p-2 gap-2"}>
                    <div className={"flex flex-row px-2 gap-1"}>
                        <InputRangeDate title={"날짜"} startTime={searchForm.fromDate} endTime={searchForm.toDate} setValue={handleDate} />
                    </div>
                    <button className={"bg-gray-700 px-3 rounded text-white text-sm"} onClick={search}>조회</button>
                </div>
            </div>
            <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 600}}>
                <AgGridReact
                    rowHeight={40}
                    headerHeight={32}
                    rowData={dataList}
                    pagination={true}
                    enableCellTextSelection={true}
                    onCellClicked={onCellClicked}
                    defaultColDef={Global.defaultColDef}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(SlotBettingList);