import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
    CContainer,
    CHeader,
    CHeaderBrand,
    CHeaderNav,
    CHeaderToggler,
    CNavLink,
    CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
    cilMenu,
    cilAccountLogout,
} from '@coreui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { accountActions } from '../../../redux/actions/account';

// 로컬 스토리지에 데이터 저장 (만료 시간 포함)
const setStorageWithExpiry = (key, value, expiryInMs = 7200000) => { // 기본 2시간
  const item = {
    value: value,
    expiry: new Date().getTime() + expiryInMs,
  };
  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.error('localStorage 저장 오류:', error);
  }
};

const AppHeader = ({ move, counts }) => {
    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.state.sidebarShow)
    const location = useLocation();
    const navigate = useNavigate();

    const onClick = (type) => {
        let menu = {}
        switch (type) {
            case "deposit":
                menu = {
                    id: 41,
                    component: CNavItem,
                    name: '입금 신청 내역',
                    to: '/cash/in-request',
                }
                break;
            case "withdraw":
                menu = {
                    id: 43,
                    component: CNavItem,
                    name: '출금 신청 내역',
                    to: '/cash/withdraw-request',
                }
                break;
            case "register":
                menu = {
                    id: 24,
                    component: CNavItem,
                    name: '가입승인 대기',
                    to: '/user/accept',
                }
                break;
            case "inquiry":
                menu = {
                    id: 50,
                    component: CNavItem,
                    name: '1:1문의',
                    to: '/customer/inquiry',
                }
                break;
        }
        
        console.log('현재 경로:', location.pathname);
        console.log('이동할 경로:', menu.to);
        
        try {
            // 메뉴의 경로에서 맨 앞의 슬래시(/)를 제거하여 비교
            const currentPath = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;
            const menuPath = menu.to.startsWith('/') ? menu.to.substring(1) : menu.to;
            
            console.log('정규화된 현재 경로:', currentPath);
            console.log('정규화된 메뉴 경로:', menuPath);
            
            // 현재 경로와 클릭한 메뉴의 경로 비교 (맨 앞의 슬래시를 제거하고 비교)
            if (currentPath === menuPath || location.pathname === menu.to) {
                console.log('같은 페이지 리로드');
                // localStorage에 현재 위치를 저장하여 새로고침 후에도 같은 페이지 유지 (만료 시간 1시간)
                setStorageWithExpiry('lastVisitedPage', menu.to, 3600000); // 3600000ms = 1시간
                // 강제로 페이지 리로드
                window.location.reload();
            } else {
                console.log('다른 페이지로 이동');
                move(menu);
            }
        } catch (error) {
            console.error('경로 처리 중 오류 발생:', error);
            // 오류가 발생해도 페이지 이동은 시도
            move(menu);
        }
    }

    // 로그아웃 처리 함수
    const handleLogout = () => {
        // Redux 상태에서 계정 정보 초기화
        dispatch(accountActions.initAccount());
        // localStorage에서 마지막 방문 페이지 정보 제거
        localStorage.removeItem('lastVisitedPage');
        // 로그인 페이지로 이동
        navigate('/login');
    }

    return (
        <CHeader position="sticky" className="mb-2">
            <CContainer fluid>
                <CHeaderToggler
                    className="ps-1"
                    onClick={() => dispatch({type: 'set', sidebarShow: !sidebarShow})}
                >
                    <CIcon icon={cilMenu} size="xl"/>
                </CHeaderToggler>
                <CHeaderNav className="d-none d-md-flex me-auto gap-2">
                    <div className={`menu-button ${counts.depositCount > 0 ? 'highlight' : ''}`} 
                         onClick={() => onClick("deposit")}>
                        <span className="text-white text-sm font-bold">입금신청</span>
                        <span className="text-white text-lg font-bold">{counts.depositCount}</span>
                    </div>
                    <div className={`menu-button ${counts.withdrawCount > 0 ? 'highlight' : ''}`} 
                         onClick={() => onClick("withdraw")}>
                        <span className="text-white text-sm font-bold">환전신청</span>
                        <span className="text-white text-lg font-bold">{counts.withdrawCount}</span>
                    </div>
                    <div className={`menu-button ${counts.userRegisterCount > 0 ? 'highlight' : ''}`} 
                         onClick={() => onClick('register')}>
                        <span className="text-white text-sm font-bold">가입신청</span>
                        <span className="text-white text-lg font-bold">{counts.userRegisterCount}</span>
                    </div>
                    <div className={`menu-button ${counts.inquiryCount > 0 ? 'highlight' : ''}`}
                         onClick={() => onClick('inquiry')}>
                        <span className="text-white text-sm font-bold">1:1문의</span>
                        <span className="text-white text-lg font-bold">{counts.inquiryCount}</span>
                    </div>
                </CHeaderNav>
                <CHeaderNav>
                    <CNavItem>
                        <CNavLink onClick={handleLogout} style={{ cursor: 'pointer' }}>
                            <CIcon icon={cilAccountLogout} size="xl"/>
                        </CNavLink>
                    </CNavItem>
                </CHeaderNav>
            </CContainer>
        </CHeader>
    )
}

export default AppHeader
