import {wsHost} from "../pages/common/Global";

class WebSocketService {
    constructor() {
        this.ws = null;
        this.subscribers = new Map();
        this.reconnectAttempts = 0;
        this.reconnectDelay = 3000; // 3초마다 재시도
        this.connectionPromise = null;
        this.isConnecting = false;  // 연결 시도 중인지 확인하는 플래그 추가
    }

    connect() {
        // 이미 연결 시도 중이면 현재 Promise 반환
        if (this.isConnecting) {
            return this.connectionPromise;
        }

        // 이미 연결되어 있고 정상 상태면 바로 resolve
        if (this.ws?.readyState === WebSocket.OPEN) {
            return Promise.resolve();
        }

        // 기존 연결이 있다면 정리
        this.cleanup();

        this.isConnecting = true;
        this.connectionPromise = new Promise((resolve) => {
            try {
                this.ws = new WebSocket(wsHost);

                this.ws.onopen = () => {
                    this.reconnectAttempts = 0;
                    this.isConnecting = false;
                    this.connectionPromise = null;
                    
                    // 연결 성공 후 기존 구독자들 재구독
                    this.resubscribeAll();
                    
                    resolve();
                };

                this.ws.onerror = (error) => {
                    this.isConnecting = false;
                    this.connectionPromise = null;
                    this.attemptReconnect();
                };

                this.ws.onclose = () => {
                    this.isConnecting = false;
                    this.connectionPromise = null;
                    this.attemptReconnect();
                };

                this.ws.onmessage = (event) => {
                    try {
                        const message = JSON.parse(event.data);
                        if (this.subscribers.has('admin')) {
                            this.subscribers.get('admin').forEach(callback => {
                                callback(message);
                            });
                        }
                    } catch (error) {
                        // 중요한 에러는 유지
                        console.error('Failed to parse message:', error);
                    }
                };
            } catch (error) {
                this.isConnecting = false;
                this.connectionPromise = null;
                this.attemptReconnect();
            }
        });

        return this.connectionPromise;
    }

    // 모든 구독자 재구독 처리
    resubscribeAll() {
        for (const [topic, callbacks] of this.subscribers.entries()) {
            if (callbacks.size > 0) {
                const userId = localStorage.getItem('userId');
                const message = {
                    "type": "subscribe",
                    "topic": topic,
                    "userId": userId
                };
                this.send(message);
            }
        }
    }

    cleanup() {
        if (this.ws) {
            // 이벤트 리스너 제거
            this.ws.onopen = null;
            this.ws.onclose = null;
            this.ws.onerror = null;
            this.ws.onmessage = null;

            // 연결이 아직 열려있다면 정상적으로 닫기
            if (this.ws.readyState === WebSocket.OPEN) {
                this.ws.close();
            }
            this.ws = null;
        }
    }

    attemptReconnect() {
        this.reconnectAttempts++;
        setTimeout(() => {
            if (!this.isConnecting && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
                this.connect();
            }
        }, this.reconnectDelay);
    }

    subscribe(topic, callback) {
        if (!this.subscribers.has(topic)) {
            this.subscribers.set(topic, new Set());
        }
        this.subscribers.get(topic).add(callback);

        // 웹소켓이 연결된 상태일 때만 구독 요청 전송
        if (this.ws?.readyState === WebSocket.OPEN) {
            const userId = localStorage.getItem('userId');
            const message = {
                "type": "subscribe",
                "topic": topic,
                "userId": userId
            };
            this.send(message);
        }
    }

    unsubscribe(topic, callback) {
        if (this.subscribers.has(topic)) {
            this.subscribers.get(topic).delete(callback);
        }

        // 구독 해제 요청 메시지 전송
        const userId = localStorage.getItem('userId');
        const message = {
            "type": "unsubscribe",
            "topic": topic,
            "userId": userId
        };
        this.send(message);
    }

    notifySubscribers(data) {
        const { type, payload } = data;
        if (this.subscribers.has(type)) {
            this.subscribers.get(type).forEach(callback => callback(payload));
        }
    }

    send(message) {
        if (this.ws?.readyState === WebSocket.OPEN) {
            const messageStr = JSON.stringify(message);
            this.ws.send(messageStr);
        }
    }

    disconnect() {
        this.cleanup();
    }
}

export const webSocketService = new WebSocketService(); 