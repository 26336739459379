import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import * as api from "../../../api/api";
import numeral from "numeral";
import {changeSportName} from "../../common/Global";

const BettingDetail = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [betting, setBetting] = useState({})
    const [bettingList, setBettingList] = useState([])

    useEffect(() => {
        setBetting(props.betting)

        search()
    }, [])

    const winning = (result) => {
        switch (result) {
            case 0:
                return "대기"
            case 1:
                return "승"
            case 2:
                return "패"
            case 8:
                return "적특"
            case 98:
                return "사용자취소"
            case 99:
                return "관리자취소"
        }
    }

    const search = () => {
        setLoading(true)
        api.getBettingDetailList(props.betting.seq).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setBettingList(data)
            }
        })
            .catch(ex => {
                console.error(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const cancel = (betting) => {
        if (window.confirm("베팅을 취소하시겠어요?")) {

            setLoading(true)
            api.cancelBetting(betting).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    const success = data.success;
                    if (success) {
                        window.alert(data.message)
                        search();
                    }
                    else {
                        window.alert(data.message)
                    }
                }
            })
                .catch(ex => {
                    console.error(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className={"flex flex-col bg-white"}>
            <div className={"flex-1"}>
                <div className={"flex-glow"}>
                    <div className={"flex flex-col gap-2 rounded border-3 border-indigo-500 mb-4"}>
                        {bettingList.map((detail, idx) => {
                            return (
                                <div key={String(idx)} className={"flex flex-col gap-1 sm:gap-2"}>
                                    <div className={"flex flex-row bg-zinc-800 justify-between p-2"}>
                                        <div className={"flex flex-row gap-1 sm:gap-2 items-center"}>
                                            <span className={"rounded-1 bg-indigo-500 text-[10px] sm:text-xs text-white text-center px-2 py-1 font-bold"}>{changeSportName(detail.sport)}</span>
                                            <img className={"w-6 h-6 sm:w-8 sm:h-8"} src={detail.ccImage} alt={""}/>
                                            <span className={"text-sm sm:text-md text-black font-bold"}>{detail.ccKr}</span>
                                            <span className={"text-xs sm:text-sm text-black"}>{detail.matchId}</span>
                                        </div>
                                        <div className={"flex flex-row items-center"}>
                                            <span className={"text-xs sm:text-sm text-black"}>{betting.instTime}</span>
                                        </div>
                                    </div>
                                    {detail.resultDiv === '10' &&
                                    <div className={"flex flex-col sm:flex-row p-2 gap-1 sm:gap-2 items-start sm:items-center"}>
                                        <span className={"text-sm text-gray-800 px-2"}>{detail.time}</span>
                                        <span className={"w-full sm:w-[100px] text-sm sm:text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                        <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                            <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Home' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.homeName}</button>
                                            <button className={`w-16 sm:w-20 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>Draw</button>
                                            <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Away' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.awayName}</button>
                                        </div>
                                        <div className={"flex flex-row items-center min-w-[140px]"}>
                                            <span className={"text-sm font-bold text-gray-600 w-[80px] text-center"}>
                                                {detail.statusId === 2 
                                                    ? `${detail.homeScore} : ${detail.awayScore}`
                                                    : detail.statusKr
                                                }
                                            </span>
                                            <span className={`w-[100px] text-sm font-bold text-center ${detail.result === 1 ? 'text-green-500' : 'text-red-500'}`}>
                                                {winning(detail.result)}
                                            </span>
                                        </div>
                                    </div>
                                    }
                                    {detail.resultDiv === '20' &&
                                    <div className={"flex flex-col sm:flex-row p-2 gap-1 sm:gap-2 items-start sm:items-center"}>
                                        <span className={"text-sm text-gray-800 px-2"}>{detail.time}</span>
                                        <span className={"w-full sm:w-[100px] text-sm sm:text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                        <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                            <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Over' ? 'bg-red-500 border-red-500 text-white' : null}`}>
                                                <div className={"flex flex-row gap-1 justify-between"}>
                                                    <span className={"text-sm"}>{detail.homeName}</span>
                                                    <span className={"text-sm"}>오버</span>
                                                </div>
                                            </button>
                                            <button className={`w-16 sm:w-20 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.oddsBase}</button>
                                            <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Under' ? 'bg-red-500 border-red-500 text-white' : null}`}>
                                                <div className={"flex flex-row gap-1 justify-between"}>
                                                    <span className={"text-sm"}>언더</span>
                                                    <span className={"text-sm"}>{detail.awayName}</span>
                                                </div>
                                            </button>
                                        </div>
                                        <div className={"flex flex-row items-center min-w-[140px]"}>
                                                    <span className={"text-sm font-bold text-gray-600 w-[80px] text-center"}>
                                                        {detail.statusId === 2
                                                            ? `${detail.homeScore} : ${detail.awayScore}`
                                                            : detail.statusKr
                                                        }
                                                    </span>
                                            <span className={`w-[60px] text-sm font-bold text-center ${detail.result === 1 ? 'text-green-500' : 'text-red-500'}`}>
                                                        {winning(detail.result)}
                                                    </span>
                                        </div>
                                    </div>
                                    }
                                    {detail.resultDiv === '30' &&
                                    <div className={"flex flex-col sm:flex-row p-2 gap-1 sm:gap-2 items-start sm:items-center"}>
                                        <span className={"text-sm text-gray-800 px-2"}>{detail.time}</span>
                                        <span className={"w-full sm:w-[100px] text-sm sm:text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                        <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                            <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Home' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.homeName}</button>
                                            <button className={`w-16 sm:w-20 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.oddsBase}</button>
                                            <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Away' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.awayName}</button>
                                        </div>
                                        <div className={"flex flex-row items-center min-w-[140px]"}>
                                            <span className={"text-sm font-bold text-gray-600 w-[80px] text-center"}>
                                                {detail.statusId === 2 
                                                    ? `${detail.homeScore} : ${detail.awayScore}`
                                                    : detail.statusKr
                                                }
                                            </span>
                                            <span className={`w-[60px] text-sm font-bold text-center ${detail.result === 1 ? 'text-green-500' : 'text-red-500'}`}>
                                                {winning(detail.result)}
                                            </span>
                                        </div>
                                    </div>
                                    }
                                </div>
                            )
                        })}
                        <div className={"h-1 border-1"} />
                        <div className={"flex flex-row p-2 items-center justify-between"}>
                            <div className={"flex flex-col"}>
                                <div className={"flex flex-row"}>
                                    <span className={"text-xs font-bold"}>배팅시간 : {betting.instTime}</span>
                                    <span className={"text-xs font-bold px-2"}> / </span>
                                    <span className={"text-xs font-bold"}>배당율 : {betting.bettingOdds}</span>
                                    <span className={"text-xs font-bold px-2"}> / </span>
                                    <span className={"text-xs font-bold"}>배팅금액 : {numeral(betting.bettingAmount).format("0,0")}원</span>
                                    {(betting.result === 99 || betting.result === 98) &&
                                    <div className={"flex flex-row"}>
                                        <span className={"text-xs font-bold px-2"}> / </span>
                                        <span className={"text-xs font-bold"}>취소시간 : {betting.cancelTime ? betting.cancelTime : betting.updTime}</span>
                                        <span className={"text-xs font-bold"}>({betting.result === 99 ? "관리자취소" : "사용자취소"})</span>
                                    </div>
                                    }
                                </div>
                                <div className={"flex flex-row"}>
                                    <span className={"text-xs font-bold"}>예상 적중금액 : {numeral(betting.bettingAmount * betting.bettingOdds).format("0,0")} 원</span>
                                    <span className={"text-xs font-bold px-2"}> / </span>
                                    <span className={"text-xs font-bold"}>당첨금 : <span className={"text-red-500"}>
                                    {betting.result === 1 ? numeral(betting.bettingAmount * betting.bettingOdds).format("0,0") : 0} 원</span></span>
                                </div>
                            </div>
                            {(betting.result !== 99 && betting.result !== 98) &&
                            <button className={"p-2 rounded bg-gray-500 text-white font-bold"} onClick={() => cancel(betting)}>베팅 취소</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-1"}>
                <button className={"flex-1 p-2 rounded bg-gray-800 font-bold text-white"} onClick={props.close}>닫기</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) =>
{
    const {account} = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(BettingDetail)